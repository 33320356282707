import { HTMLProps } from 'react';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import {
	useBanUser,
	useGetUserById,
	useUpdateUserProfile,
	useWarnUser,
} from '@/components/api/swrQueries.tsx';
import Loader from '@/components/custom/Loader.tsx';
import {
	canBanDevice,
	getGenderString,
	getMemberSince,
	isBanned,
	isBioValid,
	isCertified,
	isCertifPending,
	wait,
} from '@/lib/utils.ts';
import { AdminUpdateActionEnum, BanTypeEnum, GenderEnum } from '@/lib/enum.ts';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';
import ProfileDetailUserData from '@/components/custom/ProfileDetailUserData.tsx';

interface Interface {
	userId: string;
	isModal?: boolean;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ProfileDetails({
	userId,
	isModal = false,
	className,
}: Interface) {
	const {
		data: userData,
		isLoading: isLoadingUser,
		mutate: getUserData,
	} = useGetUserById(userId);
	const { trigger: updateUserProfile, isMutating: isUpdating } = useUpdateUserProfile();
	const { trigger: warnUser, isMutating: isWarningUser } = useWarnUser();
	const { trigger: banUser, isMutating: isBanningUser } = useBanUser();

	if (isLoadingUser) return <Loader className={'mt-8'} text={'Chargement du profil'} />;

	if (!userData) return <NoDataText text={"Cet utilisateur n'existe pas"} />;

	const onUpdateBtnsPress = async (action: AdminUpdateActionEnum) => {
		let msg = '';
		if (action === AdminUpdateActionEnum.CHANGE_GENDER) {
			msg = 'Etes-vous sur de vouloir changer son genre ?';
		} else if (action === AdminUpdateActionEnum.REMOVE_BIO) {
			msg = 'Etes-vous sur de vouloir supprimer sa bio ?';
		} else if (action === AdminUpdateActionEnum.REMOVE_CERTIFICATION) {
			msg = 'Etes-vous sur de vouloir supprimer sa certification ?';
		} else if (action === AdminUpdateActionEnum.REQUEST_CERTIF) {
			msg = 'Etes-vous sur de vouloir exiger la certification de ce profil ?';
		}
		const yes = await confirm(msg);
		if (!yes) return;
		/* handle profile update and refresh the user data */
		await updateUserProfile({ userId, action });
		await getUserData();
	};

	const onWarnPress = async () => {
		const yes = await confirm('Etes-vous sur de vouloir avertir cet utilisateur ?');
		if (!yes) return;
		await warnUser({ warnUserId: userId });
	};

	const onBanPress = async (banType: BanTypeEnum) => {
		let msg = '';
		if (banType === BanTypeEnum.DEVICE_BAN) {
			msg =
				'Etes-vous sur de vouloir bannir son téléphone ? Cet utilisateur ne pourra plus jamais utiliser Heyama';
		} else if (banType === BanTypeEnum.EMAIL_BAN) {
			msg = 'Etes-vous sur de vouloir bannir son adresse email ?';
		}
		const yes = await confirm(msg);
		if (!yes) return;
		await banUser({ userId, banType });
		await wait(userData?.pictures?.length || 1);
		await getUserData();
	};

	return (
		<div className={`flex flex-col gap-4 ${className}`}>
			{/* has all the user data formatted */}
			<ProfileDetailUserData userData={userData} isModal={isModal} />

			{/* Separator for bottom btns */}
			<Separator />

			{/* actions */}
			<span className={'text-center font-semibold'}>Actions disponibles</span>
			<div
				className={'flex flex-row flex-wrap justify-center gap-4 md:gap-6 px-2 md:px-6'}
			>
				{/* Gender */}
				{!isBanned(userData.accountStatus) ? (
					<Button
						disabled={isUpdating}
						variant={'secondary'}
						onClick={() => onUpdateBtnsPress(AdminUpdateActionEnum.CHANGE_GENDER)}
					>
						Changer sexe: {getGenderString(userData.gender)} ➡️{' '}
						{userData.gender === GenderEnum.MALE ? 'Femme' : 'Homme'} 👩🏾‍🦱
					</Button>
				) : null}

				{/* Bio */}
				{isBioValid(userData.verifications) && !isBanned(userData.accountStatus) ? (
					<Button
						disabled={isUpdating}
						variant={'secondary'}
						onClick={() => onUpdateBtnsPress(AdminUpdateActionEnum.REMOVE_BIO)}
					>
						Supprimer sa Bio 📄
					</Button>
				) : null}

				{/* request user certif */}
				{!isCertified(userData.verifications) &&
				!isCertifPending(userData.verifications) &&
				!userData.extra?.certificationRequired &&
				!userData.incognito &&
				!isBanned(userData.accountStatus) ? (
					<Button
						disabled={isUpdating}
						variant={'secondary'}
						onClick={() => onUpdateBtnsPress(AdminUpdateActionEnum.REQUEST_CERTIF)}
					>
						Exiger la certification 🤳🏾​
					</Button>
				) : null}

				{/* Email ban */}
				<Button
					variant={'secondary'}
					disabled={isBanningUser}
					onClick={() => onBanPress(BanTypeEnum.EMAIL_BAN)}
				>
					Bannir son Email 📧
				</Button>

				{/* Warn */}
				{!isBanned(userData.accountStatus) ? (
					<Button disabled={isWarningUser} variant={'secondary'} onClick={onWarnPress}>
						Avertir ⚠️
					</Button>
				) : null}

				{/* Remove user certif */}
				{isCertified(userData.verifications) && !isBanned(userData.accountStatus) ? (
					<Button
						disabled={isUpdating}
						variant={'secondary'}
						onClick={() => onUpdateBtnsPress(AdminUpdateActionEnum.REMOVE_CERTIFICATION)}
					>
						Supprimer sa Certification ❌
					</Button>
				) : null}

				{/* Device ban */}
				{canBanDevice(userData.deviceId) ? (
					<Button
						variant={'destructive'}
						disabled={isBanningUser}
						onClick={() => onBanPress(BanTypeEnum.DEVICE_BAN)}
					>
						Bannir son Téléphone 📱
					</Button>
				) : null}
			</div>

			{/* Member since */}
			<span className={'text-sm text-center text-gray-400 underline'}>
				{getMemberSince(userData.createdAt)}
			</span>
		</div>
	);
}
