import { HTMLProps } from 'react';
import {
	getAge,
	getCertificationBadge,
	getGenderString,
	getLastConnection,
	getUserCoinCount,
	isBioValid,
} from '@/lib/utils.ts';
import { IUserData } from '@/lib/interface.ts';
import routes from '@/lib/routes.tsx';
import { Link } from 'react-router-dom';
import { SquareArrowOutUpRight } from 'lucide-react';
import ProfilePictureItemGrid from '@/components/custom/ProfilePictureItemGrid.tsx';
import { VerifStatusEnum } from '@/lib/enum.ts';
import { Separator } from '@/components/ui/separator.tsx';
import ProfileBottomStats from '@/components/custom/ProfileBottomStats..tsx';

interface Interface {
	userData: IUserData;
	isModal?: boolean;
	listMode?: boolean;
	onTitleClick?: () => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ProfileDetailUserData({
	userData,
	isModal,
	listMode,
	onTitleClick,
	className,
}: Interface) {
	const coins = getUserCoinCount(userData?.coins || 0, userData?.diamondWallet?.coins);

	return (
		<>
			<div
				className={`flex flex-col items-center gap-3 ${isModal ? 'px-6 pt-6' : ''} ${
					listMode ? 'pt-4' : ''
				} ${className}`}
			>
				<div className={'flex flex-col items-center'}>
					<h1
						onClick={onTitleClick}
						className={`${
							isModal ? 'text-xl' : 'text-2xl md:text-3xl text-center'
						} font-semibold text-center ${
							onTitleClick ? 'hover:opacity-45 cursor-pointer' : ''
						}`}
					>
						{getCertificationBadge(userData.verifications)} {userData.username} •{' '}
						{getAge(userData.birthdate)} • {getGenderString(userData.gender)} •{' '}
						{userData.accountStatus}
						{userData.incognito ? ' • 🥸​' : ''}
					</h1>
					<div className={'flex flex-row items-center gap-2'}>
						{userData.countryIso3 || userData.lastSeen ? (
							<span className={'text-sm text-center text-gray-400 mt-2'}>
								{userData.countryIso3} • {getLastConnection(userData.lastSeen)}{' '}
							</span>
						) : null}
						{isModal ? (
							<Link to={`${routes.nav.profileDetails}/${userData._id}`} target={'_blank'}>
								<SquareArrowOutUpRight
									size={16}
									color={'blue'}
									className={'cursor-pointer'}
								/>
							</Link>
						) : null}
					</div>
				</div>
				{isBioValid(userData.verifications) ? (
					<h2 className={`text-md text-gray-400 text-center`}>{userData.aboutYou}</h2>
				) : null}
			</div>

			{/* pictures */}
			<ProfilePictureItemGrid
				userId={userData._id}
				showUnverifiedPictures
				pictures={userData.pictures}
				certifPhoto={
					userData.verifications?.id?.status === VerifStatusEnum.COMPLETED
						? userData.verifications?.id?.photo
						: undefined
				}
				className={isModal ? '' : 'px-0 gap-5'}
				imageContainerClassName={isModal ? '' : 'w-36 self-end'}
			/>

			<Separator />

			{/* Others stats */}
			<ProfileBottomStats
				coins={coins}
				userId={userData._id}
				className={listMode ? 'pb-4' : ''}
				hasIncognito={!!userData.incognito}
				deviceBanCount={userData.deviceBanCount}
				reviewerName={userData.verifications?.id?.reviewer?.name}
				deviceAccountsCount={listMode ? 0 : userData.deviceAccountsCount}
			/>
		</>
	);
}
