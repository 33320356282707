export default {
	nav: {
		home: '/',
		login: '/login',
		referral: '/referral',
		profileDetails: '/profile',
		subAccounts: 'accounts' /* this is a sub route */,
		bioReview: '/bio',
		findUser: '/users',
		reports: '/reports',
		verificationsHistory: '/verifications/history',
		certifications: '/certifications',
		verifications: '/verifications',
	},
	links: {
		stats: 'https://links.heyamapp.com/stats',
	},
	api: {
		login: '/admin/login',
		getConnectedUser: '/user/me',
		warnUser: '/admin/user/warn',
		banUser: '/admin/user/ban',
		getReports: '/admin/report',
		toggleMaintenance: '/admin/toggle-maintenance',
		searchUser: '/admin/user/search',
		verifyBio: '/admin/verification/bio',
		dashboard: '/admin/dashboard/cards',
		getMaintenance: '/info/maintenance',
		getUserById: '/admin/user/find',
		getUserSubAccounts: '/admin/user/sub-accounts',
		createReferralUser: '/admin/user/referral/create',
		getReferralUsers: '/admin/user/referral',
		ignoreReport: '/admin/report/ignore',
		getCertifications: '/admin/verification/id',
		getBioVerifications: '/admin/verification/bio',
		getVerifications: '/admin/verification/photo',
		getVerificationsCompleted: '/admin/verification/completed',
		updateUserProfile: '/admin/user/update',
		getCertificationCount: '/admin/verification/id/count',
		getVerificationCount: '/admin/verification/photo/count',
		deleteProfilePicture: '/admin/user/delete/picture',
		deleteReferralUser: '/admin/user/referral/delete',
		finishCertification: '/admin/verification/id',
		finishVerification: '/admin/verification/photo',
	},
};
