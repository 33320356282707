import { HTMLProps } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx';
import NumberBadge from '@/components/custom/NumberBadge.tsx';
import Loader from '@/components/custom/Loader.tsx';
import { Button } from '@/components/ui/button.tsx';
import routes from '@/lib/routes.tsx';
import { useNavigate } from 'react-router-dom';

interface Interface {
	value: string;
	isLoading?: boolean;
	onValueChange: (value: any) => void;
	data: { id: string; name: string; count?: number }[];
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ButtonTab({
	data,
	value,
	isLoading,
	onValueChange,
	className,
}: Interface) {
	const navigate = useNavigate();

	return (
		<Tabs
			value={value}
			defaultValue={value}
			className={className}
			onValueChange={onValueChange}
		>
			<div className={'flex flex-col items-center gap-2'}>
				<TabsList>
					{data.map(item => {
						return (
							<TabsTrigger key={item.id} value={item.id}>
								{item.name}{' '}
								{isLoading ? (
									<Loader className={'ml-2'} />
								) : (
									<NumberBadge value={item.count} isActive={value === item.id} />
								)}
							</TabsTrigger>
						);
					})}
				</TabsList>
				<Button
					variant={'link'}
					onClick={() => navigate(routes.nav.verificationsHistory)}
				>
					Afficher l'historique
				</Button>
			</div>
		</Tabs>
	);
}
